import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  // if (posts.length === 0) {
  //   return (
  //     <Layout location={location} title={siteTitle}>
  //       <Seo title="All posts" />
  //       <Bio />
  //       <p>
  //         No blog posts found. Add markdown posts to "content/blog" (or the
  //         directory you specified for the "gatsby-source-filesystem" plugin in
  //         gatsby-config.js).
  //       </p>
  //     </Layout>
  //   )
  // }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Belfast JS" />
      {/* <Bio /> */}
      <h2>What’s the craic?</h2>
      <p>
        Belfast JS is a celebration of all things dev. Everyone is welcome here.
        All our talks and events are open call, meaning if you’re well seasoned
        or fresh to the field, this is the platform for you to share, discuss
        and learn together within this diverse and exciting community in
        Belfast.
      </p>
      <p>
        Even if you are more java than javascript or more design than dev, we
        will have something for you. We are passionate about building
        collaborative relationships and knowledge through peer learning.
      </p>
      <p>
        Events will take a variety of formats from panel discussions to
        presentations and more. We will call for speakers either through{" "}
        <a href="https://www.meetup.com/Belfast-JS/" target="_blank">
          Meetup
        </a>{" "}
        or via{" "}
        <a href="https://twitter.com/belfastjs" target="_blank">
          Twitter
        </a>{" "}
        and encourage everyone to participate!
      </p>
      {/* <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>{post.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </li>
          )
        })}
      </ol> */}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
